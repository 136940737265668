<template>
  <div v-if="shops">
    <CRow>
      <CCol md="12" lg="12">
        <CCard class="shadow-sm">
          <CCardBody>
            <table aria-describedby="">
              <tr>
                <th scope="col" class="text-center">
                  <img
                    alt=""
                    src="/logo-ab.png"
                    class="img-fluid"
                    width="70%"
                  />
                  <CRow class="d-flex justify-content-center">
                    <CCol md="8">
                      <a href="http://line.me/ti/p/@silompos">
                        <CButton color="success" block>
                         {{ $t('contactCustomerService') }}
                        </CButton>
                      </a>
                    </CCol>
                  </CRow>
                </th>
                <td style="width:60%">
                  <router-link :to="'/crm/menu'">
                    <img alt="" src="/img/crm.jpg" class="img-fluid" />
                  </router-link>
                </td>
              </tr>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop.vue'

export default {
  components: { NoShop },
  computed: {
    ...mapGetters(['users', 'shops']),
  },
  mounted() {
    this.$store.dispatch('getUser')
  },
}
</script>
